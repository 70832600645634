import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import { 
  ManagerJournalRowResource,
  ManagerMasterGroupsMasterGroupIdJournalGetParams,
  ManagerMasterGroupsMasterGroupIdJournalGetRequest,
} from '@/store/types'


@Module({
  dynamic: true,
  name: 'ManagerJournal',
  namespaced: true,
  store,
})
class ManagerJournal extends VuexModule {
  journal: ManagerJournalRowResource[] = []

  @Mutation
  setJournal (payload: ManagerJournalRowResource[]) {
    this.journal = [...payload]
  }

  @Action({ rawError: true })
  async fetchJournal (payload: {masterGroupId: number, params?: ManagerMasterGroupsMasterGroupIdJournalGetParams }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdJournalGetRequest(payload.masterGroupId, payload.params)
    this.setJournal(data)
    return data
  }
}

const ManagerJournalModule = getModule(ManagerJournal)

export default ManagerJournalModule














































import shave from 'shave'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { uniqBy, orderBy } from 'lodash'

// components
import Hearts from '@/components/Hearts.vue'
import NoDataFound from '@/components/NoDataFound.vue'
// store
import ManagerJournalModule from '@/store/modules/manager/journal'
import { JournalLargeExerciseResource, ManagerJournalRowResource } from '@/store/types'

@Component({
  components: {
    Hearts,
    NoDataFound,
  },
})
export default class ManagerExercisesJournal extends Vue {
  @Prop({ default: false })
  private specialCourseView!: boolean // Note: Для спецкурсов другое отображдение журнала

  private heights: number[] = []

  private get totalHeight() {
    return this.heights.reduce((acc, val) => acc + val, 0) + this.heightHeader
  }

  private heightHeader = 0

  private scrollOptions = {
    vuescroll: {
      sizeStrategy: 'number',
      wheelDirectionReverse: true,
    },
  }

  private get orderedExercises() {
    let arr: JournalLargeExerciseResource[] = []

    this.journal.forEach((exercise: ManagerJournalRowResource) => {
      arr.push(...exercise.exercises)
    })

    arr = uniqBy(arr, 'id')

    return arr
  }

  private get journal() {
    const journal = orderBy(ManagerJournalModule.journal.map(item => ({
      ...item,
      total: this.getOverallScore(item.exercises),
    })), ['total'], ['desc'])
    return journal
  }

  private mounted () {
    window.addEventListener('resize', this.handleWindowResize)
    this.heightHeader = (this.$refs.headers as HTMLDivElement)?.offsetHeight || 0
    const columns = this.$refs.row as HTMLDivElement[] || []
    this.$nextTick(() => {
      if (columns.length) {
        columns.forEach(el => this.heights.push(el.offsetHeight))
      }
    })

    shave('.manager-journal-table-course-name', 18 * 2)
  }

  private destroyed() {
    window.removeEventListener('resize', this.handleWindowResize)
  }

  private handleWindowResize() {
    this.heightHeader = (this.$refs.headers as HTMLDivElement)?.offsetHeight || 0
    const columns = this.$refs.row as HTMLDivElement[] || []
    this.heights = []
    if (columns.length) {
      columns.forEach(el => this.heights.push(el.offsetHeight))
    }
  }

  private getOverallScore(exercises: JournalLargeExerciseResource[]) {
    return exercises.reduce((acc: number, cur: JournalLargeExerciseResource) => {
      return acc += cur.points
    }, 0)
  }

}





























import { MetaInfo } from 'vue-meta'
import { Component, Mixins, Watch } from 'vue-property-decorator'

// components
import ManagerExercisesJournal from '@/components/views/education/exercises/ManagerExercisesJournal.vue'
import Select from '@/components/_uikit/controls/Select.vue'
// store
import ManagerJournalModule from '@/store/modules/manager/journal'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import MasterGroupsSearch from '@/components/_uikit/search/MasterGroupsSearch.vue'
import { CourseType } from '@/store/types'
import MentorEducationModule from '@/store/modules/mentor/education'

@Component({
  components: {
    ManagerExercisesJournal,
    MasterGroupsSearch,
    Select,
  },
})
export default class ExercisesJournal extends Mixins(MasterGroupMixin) {
  private monthId: number | null = null
  private isLoaded = false
  // Note: переменная isLoaded необходима, так как необходимо размаунтить компонент при обновелнии данных, из-за особенности работы Vuescroll
  //       scroll берет текущую высоту контейнера при маутинге, динамически её не обновляя..

  private get breadcrumbs () {
    return [{ name: 'Домашняя работа', path: '/manager/education/exercises' }]
  }

  private mounted () {
    if (this.masterGroups.length > 0) {
      if (this.currentMasterGroupMonth) {
        this.monthId = this.currentMasterGroupMonthID as number
      } else {
        this.fetchJournal()
      }
    } else {
      this.notifyError('У вас нет доступа к разделу "Журнал заданий"')
      this.$router.replace({ name: 'profile' })
    }
  }

  private get isCurrentMasterGroupSpecial() {
    return this.currentMasterGroup.course.type.value === CourseType.SPECIAL
  }

  private fetchJournal () {
    this.isLoaded = false
    ManagerJournalModule.fetchJournal({
      masterGroupId: this.currentMasterGroupID,
      params: { monthId: this.monthId as number },
    })
      .catch((error) => {
        this.notifyError(error)
        this.$router.push({ name: 'manager.education.exercises' })
      })
      .finally(() => this.isLoaded = true)
  }

  @Watch('currentMasterGroupID')
  watchCurrentMasterGroupID () {
    MentorEducationModule.setSavedMasterGroupMounthID(null)
    if (this.currentMasterGroupMonth) {
      this.monthId = this.currentMasterGroupMonthID as number
    }
  }

  @Watch('monthId')
  watchFilter (value: number) {
    MentorEducationModule.setSavedMasterGroupMounthID(value)
    this.fetchJournal()
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Журнал',
    }
  }
}
